import { Route, HashRouter } from 'react-router-dom';
import React from 'react';
import App from './components/App';
import theme from './Theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import LoggedInUserContext from '../loggedInUserContext';
import LoadingPage from './components/LoadingPage';
import LoginView from './components/LoginView';
import SignupView from './components/SignupView';
import MainView from './components/MainView';
import DocumentList from './components/DocumentList';
import NewQuestionView from './components/NewQuestionView';
import ForgotPasswordView from './components/ForgotPasswordView';
import MainWizardView from './components/MainWizardView';
import iframeWizardView from './components/iframeWizardView';
import ClauseConditionsView from './components/ClauseConditionsView';
import PhraseView from './components/PhraseView';
import DocumentWithCheckLists from "./components/DocumentWithCheckLists";
import MainPage from "./components/MainPage";
import AskAi from "./components/AskAi";
import KeepClause from "./components/KeepClause";
import KeepClauseEdit from "./components/KeepClauseEdit";
import ClausesPorfolio from "./components/ClausesPorfolio";

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (<LoggedInUserContext.Consumer>
        {context => ( 
            <Route {...rest} render={props =>
                context.user_id ? (
                    <Component {...props} />
                ) : localStorage.getItem('access_token') ? (<LoginView {...props} />) : (<SignupView {...props} /> ) }
        />)}
    </LoggedInUserContext.Consumer>);
};

const LegalupRouter = () => {
    return <HashRouter basename='/'>
        <MuiThemeProvider theme={theme}>
            <App>
                <Route exact path="/login" component={LoginView} />
                <Route exact path="/signup" component={SignupView} />
                <Route exact path="/wizard/:document_id?" component={MainWizardView} />
                <Route exact path="/document/:document_id?/iframe/:filename?" component={iframeWizardView} />
                <Route exact path="/forgotPassword" component={ForgotPasswordView} />

                <PrivateRoute exact path="/" component={LoadingPage} />
                <PrivateRoute exact path="/askAi" component={AskAi} />
                <PrivateRoute exact path="/keepClause" component={KeepClause} />
                <PrivateRoute exact path="/keepClauseEdit/:clause_id?" component={KeepClauseEdit} />
                <PrivateRoute exact path="/clausesPorfolio" component={ClausesPorfolio} />
                <PrivateRoute exact path="/list" component={DocumentList} />
                <PrivateRoute exact path="/checkLists" component={DocumentWithCheckLists} />
                <PrivateRoute exact path="/mainPage" component={MainPage} />
                <PrivateRoute path="/main/:selected_document_id?" component={MainView} />
                <PrivateRoute path="/question/:question_id?" component={NewQuestionView} />
                <PrivateRoute path="/phrase/:clause_id/:phrase_id" component={PhraseView} />
                <PrivateRoute path="/clause/:clause_id?" component={ClauseConditionsView} />
            </App>
        </MuiThemeProvider>
    </HashRouter>;
}

export default LegalupRouter;