import React, { useState, useEffect } from 'react';
import {Link, Redirect} from 'react-router-dom';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, LinearProgress, Button, IconButton, Menu, Fade, FormControl} from '@material-ui/core';
import Logo from './Logo';
import StyledMenuItem from './StyledMenuItem';
import * as Actions from '../../redux/actions';
import MenuIcon from '@material-ui/icons/Menu';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createVariableContainer, createPhraseContainer, createSignatureContainer } from '../../wordApiUtils';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import StyledSelect from "./StyledSelect";
import Tooltip from "@material-ui/core/Tooltip";
import {saveDocumentCategoryId, saveDocumentFinalFormat} from "../../redux/actions";
import DropdownAutocompleteForCategory from "./DropdownAutocompleteForCategory";

const useStyles = makeStyles(theme => ({
    mainHeader: {
        backgroundColor: 'inherit!important',
        borderBottom: 'none',
        boxShadow: 'none!important',
        width: '100%',
        zIndex: 1000,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: '48px',
        position: 'relative',
    },
    mainMenu: {
        zIndex: 99999
    },
    logoutButton: {
        cursor: 'pointer',
        marginRight: '40px',
        minWidth: '17px',
        marginLeft: '5px',
    },
    linkButton: {
        textDecoration: 'underline',
        fontFamily: 'Assistant',
        fontSize: '12px',
        color: theme.palette.primary.main
    },
    sync: {
      display: 'block',
    },
    link: {
        padding: '0',
        lineHeight: '25px',
        height: '25px',
        background: 'none',
        textAlign: 'center',
        margin: '0 4px',
        textDecoration: 'none',
        width: '17px',
        alignSelf: 'center',
        justifyContent: 'center',
        display: 'flex',
        boxSizing: 'border-box',
        '&:hover' : {
            backgroundColor: 'none',
            cursor: 'pointer',
        },
    },

    svg: {
        alignSelf: 'center',
        display: 'flex',
        '& path': {
            color: '#1174E5',
            fill:  '#1174E5',
        }
    }
}));

function PrimarySearchAppBar(props) {
    const classes = useStyles();
    const showSave = (props.showButton === false) ?  false : true;
    const [redirect, setRedirect] = useState(undefined);
    const documentId = useSelector(state => state.document.documentId, shallowEqual)
    const errors = useSelector(state => state.questions.errors);
    const loadingPhase = useSelector(state => state.document.loadingPhase);
    const singleClauseId = useSelector(state => state.document.singleClauseId);
    const isLoading = loadingPhase !== undefined && loadingPhase.length > 0;
    const vals = Object.values(errors);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const showLogout = localStorage.getItem('access_token') !== null;
    const document = useSelector(state => state.document);
    const currentUser = localStorage.getItem('current_user');
    const organization = useSelector(state => state.organization);
    const [openD, setOpenD] = React.useState(false);
    const [openCat, setOpenCat] = React.useState(false);
    const [openDeletionOption, setOpenDeletionOption] = React.useState(false);
    const [openSignatureOption, setOpenSignatureOption] = React.useState(false);
    const [url, setUrl] = React.useState('');
    const [deletionOption, setDeletionOption] = React.useState(null);
    const [signatureOption, setSignatureOption] = React.useState(null);
    const { isTrueVal, URL } = url;
    const saveCategory = useSelector(state => state.organization.saveCategory);
    const documentCategoryId = useSelector(state => state.document.document_category_id);

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpenD(true);
    };

    const assignCategory = () => {
        setOpenCat(true);
    };


    const handleClickOpenDeletionOption = () => {
        setOpenDeletionOption(true);
    };

    const handleClickOpenSignatureOption = () => {
        setOpenSignatureOption(true);
    };

    const handleClose = () => {
        setUrl({
            URL:document.on_finish_target_origin, isTrueVal: false
        });
        setOpenD(false);
    };

    const handleCloseCategory = () => {
        setOpenCat(false);
    };

    const handleOkCategory = () => {
        if(saveCategory.id) {
            dispatch(
                Actions.saveDocumentCategoryId({ documentId, document_category_id: saveCategory.id})
            );
        }
    };

    const handleCloseDeletionOption = () => {
        setOpenDeletionOption(false);
    };

    const handleCloseSignatureOption = () => {
        setOpenSignatureOption(false);
    };


    const handleOkDialog = () => {
        dispatch(
            Actions.saveDocumentOnFinishTargetOrigin({ documentId, on_finish_target_origin: URL})
        );
        setOpenD(false);
    };

    const handleOkDialogDeletionOption = () => {
        if(deletionOption !== ''){
            dispatch(
                Actions.saveDocumentAutoDeleteQuestionnairesTime({ documentId, auto_delete_questionnaires_time: deletionOption})
            );
            setOpenDeletionOption(false);
        }

    };


    const handleOkDialogSignatureOption = () => {
        if(signatureOption !== ''){
            dispatch(
                Actions.saveSignatureType(
                    { documentId,
                        signature_type: (signatureOption !== null && signatureOption === 0)
                            ? 'docusign'
                            : (signatureOption === null && document.signature_type)
                                ? document.signature_type || 'docusign'
                                : (signatureOption !== null && signatureOption === 1)
                                    ? 'draw'
                                    : 'docusign'
                    })
                );

            setOpenSignatureOption(false);
        }

    };


    const urlPatternValidation = URL => {
        const regex = new RegExp('^(http(s)?:\\/\\/)[\\w.-]+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$');
        return regex.test(URL);
    };

    const changeUrl = event => {
        const { value } = event.target;
        const isTrueVal = !value || urlPatternValidation(value);

        setUrl({
            URL: value,
            isTrueVal
        });
    };

    const changeDeletionOption = event => {
        const { value } = event.target;
        setDeletionOption(
            value
        );
    };

    const changeDeletionOptionFromSelect = event => {
        const { value } = event.target;

        setDeletionOption(
            value === 'Do not delete' ? null : value === 'Deletion after generation' ? 0 : 1
        );
    };

    const changeSignatureOptionFromSelect = event => {
        const { value } = event.target;
        setSignatureOption(
            value === 'docusign' ? 0 : 1
        );
    };

    let user = {};
    if(currentUser) {
      user = JSON.parse(currentUser);
    }

    useEffect(() => {
        if(user.organization_id){
            dispatch(Actions.loadOrganization(user.organization_id));
        }
        setUrl({
            URL:document.on_finish_target_origin, isTrueVal: false
        });
    }, []);

    useEffect(() => {
        setUrl({
            URL:document.on_finish_target_origin, isTrueVal: false
        });
        setDeletionOption(document.auto_delete_questionnaires_time)

    }, [document]);

    let noErrors = true;
    for (let i = 0; i < vals.length; i++) { 
        if (vals[i] !== undefined && vals[i] !== null) {
            noErrors = false;
            break;
        }
    }

    const handlePlay = () => {
        if (documentId)
            setRedirect(`/wizard?document_id=${documentId}`);
    };

    const handleSave = () => {
        if (singleClauseId) {
            console.log('saving alternatives');
            dispatch(Actions.saveClauseAlternatives());
        } else {
            console.log('saving document');
            dispatch(Actions.saveDocument());
        }
    };

    const handleSyncOnChange = (event) => {
      dispatch(
        Actions.saveDocumentMondaySync({ documentId, sync_monday: event.target.checked})
      );
    }

    const handleIsChecklistOnChange = (event) => {
        dispatch(
            Actions.saveDocumentIsChecklist({ documentId, is_checklist: event.target.checked})
        );
    }

    const handleFinalFormatOnChange = (event) => {
        dispatch(
            Actions.saveDocumentFinalFormat({ documentId, final_format: event.target.checked ? 'pdf' : 'docx'})
        );
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const createPhrase = async () => {
        return Word.run(async (context) => {
            await createPhraseContainer(context);
        }).catch(error => {
            console.error(error.stack);
            console.error(error);
        }).finally(handleMenuClose);

    };

    const createVariable = async () => {
        return Word.run(async (context) => {
            const contentControl = await createVariableContainer(context);
            dispatch(Actions.setupVariable(contentControl.id));
        }).catch(error => {
            console.error(error);
        }).finally(handleMenuClose);
    };

    const createSignature = async () => {
        return Word.run(async (context) => {
            const contentControl = await createSignatureContainer(context);
            dispatch(Actions.setupSignature(contentControl.id));
        }).catch(error => {
            console.error(error);
        }).finally(handleMenuClose);
    };

    const logout = async () => {
        localStorage.removeItem('access_token');
        dispatch(Actions.resetReducer());
        await setRedirect('/login');
    };

    const linkToIframe = `/document/${documentId}/iframe/testDocument`;

    return redirect ? <Redirect to={redirect} /> : (
        <AppBar className={classes.mainHeader}>
            <Logo />
            {documentId &&
            <IconButton onClick={()=>{}}>
                <Link to={linkToIframe}><PlayCircleOutlineIcon  color="primary" /></Link>
            </IconButton>
            }

            {!documentId && <>
                <Tooltip title="Wizards" arrow placement="bottom">
                    <span>
                        <Link to='/list' className={classes.link}>
                            <svg className={classes.svg} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.2079 2.08125L13.2588 1.68458V8.08083L14.98 3.93C15.2704 3.2075 14.9375 2.37875 14.2079 2.08125ZM0.395448 4.70208L3.90878 13.1667C4.12837 13.7121 4.64545 14.045 5.19087 14.0592C5.37503 14.0592 5.56628 14.0238 5.75045 13.9458L10.9709 11.7854C11.5021 11.5658 11.8279 11.0417 11.8421 10.5175C11.8492 10.3333 11.8138 10.1279 11.75 9.94375L8.20836 1.47917C8.00295 0.93375 7.47878 0.600833 6.92628 0.59375C6.74211 0.59375 6.55795 0.63625 6.38086 0.7L1.16753 2.86042C0.445032 3.15792 0.0979484 3.98667 0.395448 4.70208ZM11.835 2.01042C11.835 1.63469 11.6858 1.27436 11.4201 1.00868C11.1544 0.743005 10.7941 0.59375 10.4184 0.59375H9.39128L11.835 6.50125" fill="white"/>
                            </svg>
                        </Link>
                    </span>
                </Tooltip>
                <Tooltip title="CheckLists" arrow placement="bottom">
                    <span>
                        <Link to='/checkLists' className={classes.link}>
                            <svg className={classes.svg} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.5834 11.3333C15.5834 11.7091 15.4341 12.0694 15.1684 12.3351C14.9028 12.6007 14.5424 12.75 14.1667 12.75H5.66669C4.88044 12.75 4.25003 12.1125 4.25003 11.3333V2.83333C4.25003 2.04708 4.88044 1.41666 5.66669 1.41666H14.1667C14.5424 1.41666 14.9028 1.56592 15.1684 1.8316C15.4341 2.09727 15.5834 2.45761 15.5834 2.83333V11.3333ZM11.3334 14.1667V15.5833H2.83336C2.45764 15.5833 2.0973 15.4341 1.83163 15.1684C1.56595 14.9027 1.41669 14.5424 1.41669 14.1667V4.95833H2.83336V14.1667H11.3334ZM9.20836 9.91666L14.1667 4.95833L13.1679 3.95958L9.20836 7.91208L7.01961 5.73041L6.02086 6.72916L9.20836 9.91666Z" fill="white"/>
                            </svg>
                        </Link>
                    </span>
                </Tooltip>
            </>
            }

            <div className={classes.grow} />            
            {showLogout &&
                <Tooltip title="log out" arrow placement="bottom">
                    <svg onClick={logout} className={classes.logoutButton} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1 11.75V9.80001H7.85C7.13203 9.80001 6.55 9.21798 6.55 8.50001C6.55 7.78204 7.13203 7.20001 7.85 7.20001H11.1V5.25001L14.35 8.50001L11.1 11.75ZM9.8 2C10.1448 2 10.4754 2.13696 10.7192 2.38076C10.963 2.62456 11.1 2.95522 11.1 3.3V3.95C11.1 4.30899 10.809 4.6 10.45 4.6C10.091 4.6 9.8 4.30899 9.8 3.95V3.3H3.95V13.7H9.8V13.05C9.8 12.691 10.091 12.4 10.45 12.4C10.809 12.4 11.1 12.691 11.1 13.05V13.7C11.1 14.0448 10.963 14.3755 10.7192 14.6193C10.4754 14.8631 10.1448 15 9.8 15H3.95C3.60521 15 3.27455 14.8631 3.03076 14.6193C2.78696 14.3755 2.64999 14.0448 2.64999 13.7V3.3C2.64999 2.95522 2.78696 2.62456 3.03076 2.38076C3.27455 2.13696 3.60521 2 3.95 2H9.8Z" fill="#FF5959"/>
                    </svg>
                </Tooltip>

            }
            {isLoading && <LinearProgress color="primary" style={{width: '140px', marginRight: '22px'}} />}
            {!isLoading && showSave && <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleSave}>
                <SaveIcon color="primary" />
            </IconButton>}
            {documentId && <IconButton style={{'marginRight': '12px'}} onClick={event => setAnchorEl(event.currentTarget)}
                edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <MenuIcon color="primary" />
            </IconButton>}
            <Menu style={{marginTop: '24px'}}
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                className={classes.mainMenu}
                onClose={handleMenuClose}
                TransitionComponent={Fade}
            >
                <StyledMenuItem onClick={createVariable}>Add Variable</StyledMenuItem>
                <StyledMenuItem onClick={createPhrase}>Add Phrase</StyledMenuItem>
                <StyledMenuItem onClick={createSignature}>Add Signature</StyledMenuItem>
                <StyledMenuItem onClick={handleClickOpen}>Iframe settings</StyledMenuItem>
                <StyledMenuItem onClick={assignCategory}>Assign category</StyledMenuItem>
                <StyledMenuItem onClick={handleClickOpenDeletionOption}>Deletion option</StyledMenuItem>
                <StyledMenuItem onClick={handleClickOpenSignatureOption}>Signature types</StyledMenuItem>

                {organization.monday_key &&
                    <StyledMenuItem>
                        <FormControlLabel
                            control={<Checkbox disabled={!!isLoading} checked={document.sync_monday} onChange={handleSyncOnChange} name="sync_monday" />}
                            label="Monday Sync"
                        />
                    </StyledMenuItem>
                }

                <StyledMenuItem>
                    <FormControlLabel
                        control={<Checkbox disabled={!!isLoading} checked={document.is_checklist} onChange={handleIsChecklistOnChange} name="is_checklist" />}
                        label="Is checklist"
                    />
                </StyledMenuItem>

                <StyledMenuItem>
                    <FormControlLabel
                        control={<Checkbox disabled={!!isLoading} checked={document.final_format==='pdf'} onChange={handleFinalFormatOnChange} name="final_format" />}
                        label="PDF Format"
                    />
                </StyledMenuItem>

            </Menu>
            <Dialog open={openD} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Iframe settings</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="url"
                        label="Url"
                        type="text"
                        fullWidth
                        onChange={changeUrl}
                        value={URL}
                        error={!isTrueVal}
                        placeholder='https://google.com'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOkDialog} disabled={!isTrueVal} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCat} onClose={handleCloseCategory} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Category settings</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <DropdownAutocompleteForCategory/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOkCategory} color="primary">
                        Save
                    </Button>
                    <Button onClick={handleCloseCategory} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDeletionOption} onClose={handleCloseDeletionOption} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Deletion option</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>

                    <div className={classes.sectionContainer}>
                        <FormControl className={classes.formControl}>
                            <StyledSelect
                                name='num_lines'
                                className={classes.select}
                                value={deletionOption === 0 ? 'Deletion after generation' : (deletionOption === null || !deletionOption) ? 'Do not delete' : 'Deletion after ___ days'}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}
                                onChange={changeDeletionOptionFromSelect}
                            >
                                {['Do not delete', 'Deletion after generation', 'Deletion after ___ days'].map(i => (
                                    <StyledMenuItem dense value={i} key={`menu_${i}`}>{`${i}`}</StyledMenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                    </div>
                    {
                        deletionOption > 0 &&
                            (<TextField
                                autoFocus
                                margin="dense"
                                id="url"
                                label="Deletion after ___ days"
                                type="number"
                                fullWidth
                                onChange={changeDeletionOption}
                                value={deletionOption}

                            />)
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeletionOption} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOkDialogDeletionOption} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openSignatureOption} onClose={handleCloseSignatureOption} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Signature option</DialogTitle>
                <DialogContent>
                    <div className={classes.sectionContainer}>
                        <FormControl style={{width:'100%'}} className={classes.formControl}>
                            <StyledSelect
                                name='num_lines'
                                className={classes.select}
                                value={
                                    (signatureOption === null && document.signature_type === 'docusign') || (signatureOption === 0)
                                        ? 'docusign'
                                        : 'draw'
                                }
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}
                                onChange={changeSignatureOptionFromSelect}
                            >
                                {['docusign', 'draw'].map(i => (
                                    <StyledMenuItem dense value={i} key={`menu_${i}`}>{`${i}`}</StyledMenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSignatureOption} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOkDialogSignatureOption} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </AppBar>
    );
}
export default PrimarySearchAppBar;