import api from '../api';
import { getClauseContainer, releaseContentControls, updatePhraseContainer, deletePhraseContainer } from '../wordApiUtils';
import { ObjectId } from '../utils';
import { io } from 'socket.io-client';
import { BASE_URL } from '../api';

export const LOAD_DOCUMENT = 'LOAD_DOCUMENT';
export const LOAD_PROGRESS = 'LOAD_PROGRESS';
export const SAVE_DOCUMENT = 'SAVE_DOCUMENT';
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const FETCH_DOCX = 'FETCH_DOCX';

export const OPEN_CLAUSE = 'OPEN_CLAUSE';
export const UPDATE_CLAUSE = 'UPDATE_CLAUSE';
export const SELECT_CLAUSE = 'SELECT_CLAUSE';
export const DELETE_ALTERNATIVE = 'DELETE_ALTERNATIVE';
export const SETUP_VARIABLE = 'SETUP_VARIABLE';
export const SETUP_SIGNATURE = 'SETUP_SIGNATURE';
export const SAVE_VARIABLE = 'SAVE_VARIABLE';
export const SAVE_SIGNATURE = 'SAVE_SIGNATURE';

export const SETUP_PHRASE = 'SETUP_PHRASE';
export const UPDATE_PHRASE = 'UPDATE_PHRASE';
export const DELETE_PHRASE = 'DELETE_PHRASE';

export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const COPY_DOCUMENT = 'COPY_DOCUMENT';
export const ASK_AI = 'ASK_AI';
export const ASK_AI_MAIN = 'ASK_AI_MAIN';
export const DOCUMENT_TYPE = 'DOCUMENT_TYPE';
export const SAVE_DOCUMENT_TYPE = 'SAVE_DOCUMENT_TYPE';
export const CLAUSE_CATEGORIES = 'CLAUSE_CATEGORIES';
export const SAVE_CLAUSE = 'SAVE_CLAUSE';
export const CLAUSES_PORTFOLIO_SEARCH = 'CLAUSES_PORTFOLIO_SEARCH';
export const GET_CLAUSES = 'GET_CLAUSES';
export const GET_DOCUMENT_WITHOUT_DOCUMENT_ID = 'GET_DOCUMENT_WITHOUT_DOCUMENT_ID';
export const LOAD_ORGANIZATION = 'LOAD_ORGANIZATION';
export const DELETE_CLAUSES = 'DELETE_CLAUSES';
export const EDIT_CLAUSES = 'EDIT_CLAUSES';
export const RESET_REDUCER = 'RESET_REDUCER';
export const RESET_TAGS = 'RESET_TAGS';
export const RESET_CLAUSE_SEARCH = 'RESET_CLAUSE_SEARCH';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SAVE_CATEGORY_IN_MEMORY = 'SAVE_CATEGORY_IN_MEMORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';


const initPushUpdates = (dispatch, documentId) => {
    const socket = io(BASE_URL, {
        reconnectionDelayMax: 10000,
        secure: true
      });
      
      socket.on('connect', () => {
          console.log('socket.io connected - ' + socket.id);
          socket.emit('document_opened', documentId);

          socket.on('clause_updated', clause => {
              dispatch({ type: UPDATE_CLAUSE, push: true, success: true, clause });
          });
          
          socket.on('phrase_updated', phrase => {
              dispatch({ type: UPDATE_PHRASE, push: true, success: true, phrase });
          });
          
          socket.on('question_updated', question => {
              dispatch({ type: UPDATE_QUESTION, push: true, success: true, question });
              dispatch(loadDocument(documentId));
          });
      });
}

export const initGlobalWS = (dispatch) => {
    const socket = io(BASE_URL, {
        reconnectionDelayMax: 10000,
        secure: true
    });

    socket.on('connect', () => {
        console.log('socket.io connected - ' + socket.id);

        socket.on('document_cloned', ({request_id, ...document}) => {
            dispatch({ type: COPY_DOCUMENT, push: true, success: true, document});
        });
    });
}

async function clearDocument(context) {
    const { sections, body } = context.document;
    //note that for builds supporting 1.1 you can super simpify this to 
   // context.document.sections.getFirst().getHeader("primary").clear(); await context.sync();
    //but this sample assumes the Word Sku is 1.1
    context.load(sections);
    await context.sync();
    for (let i = 0; i < sections.items.length; i++) {
        sections.items[i].getHeader('primary').clear();
        sections.items[i].getFooter('primary').clear();
    }
    body.clear();
    await context.sync();
}

export function getDocument() {
    return async (dispatch) => {
        dispatch({ type: GET_DOCUMENT_WITHOUT_DOCUMENT_ID });

        await Word.run(async context => {
            await releaseContentControls(context);

            let paragraphs = context.document.body.paragraphs;

            paragraphs.load('text');
            await context.sync();

            let docName = '';
            for (let i = 0; i < paragraphs.items.length; ++i) {
                const p = paragraphs.items[i];
                if (!p.text.trim()) {
                    continue;
                }

                if (!docName) {
                    const name = p.text.length > 20 ? p.text.substring(0, 20) + '...' : p.text;
                    docName = name;
                    break;
                }
            };

            const xml = context.document.body.getOoxml();
            await context.sync();
            dispatch({ type: GET_DOCUMENT_WITHOUT_DOCUMENT_ID, success: true, name:docName, xml:xml.value });

        })
    };
}

export function importDocument() {
    return async (dispatch) => {
        dispatch({ type: LOAD_DOCUMENT });

        await Word.run(async context => {
            await releaseContentControls(context);

            let paragraphs = context.document.body.paragraphs;
            paragraphs.load('text');
            await context.sync();
            let docName = '';
            for (let i = 0; i < paragraphs.items.length; ++i) {
                const p = paragraphs.items[i];
                if (!p.text.trim()) {
                    continue;
                }

                if (!docName) {
                    const name = p.text.length > 20 ? p.text.substring(0, 20) + '...' : p.text;
                    docName = name;
                    break;
                }
            };
            dispatch({ type: SAVE_DOCUMENT }); 
            dispatch({ type: LOAD_PROGRESS, loadProgress: 10 });
            const xml = context.document.body.getOoxml();
            await context.sync();
            const doc = await api.documents.createDocument({
                name: docName,
                raw_data: xml.value
            });
            dispatch({ type: LOAD_PROGRESS, loadProgress: 70 });
            await clearDocument(context);
            dispatch({ type: LOAD_PROGRESS, loadProgress: 80 });
            context.document.body.insertOoxml(doc.raw_data, Word.InsertLocation.replace);
            await context.sync();
            dispatch({ type: LOAD_PROGRESS, loadProgress: 100 });
            initPushUpdates(dispatch, doc.id);
            await dispatch({ type: SAVE_DOCUMENT, success: true, document: doc });
        }).catch((error) => {
            dispatch({ type: SAVE_DOCUMENT, success: false, error });
            console.log('Error in import document: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    };
}

export function loadDocx(id, name) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DOCX });
        return Word.run(async context => {
            const docx = await api.documents.getDocumentDocx(id);
            const newDoc = context.application.createDocument(docx);
            newDoc.properties.title = '[LegalUp Template] - ' + name;
            newDoc.properties.author = 'LegalUP';
            newDoc.properties.company = 'LegalUP';
            newDoc.properties.category = 'document';
            newDoc.properties.keywords = id;
            newDoc.properties.comments = 'This document is a LegalUP wizard template';
            context.load(newDoc);

            await context.sync();
            newDoc.open();
            await context.sync();    
            dispatch({ type: FETCH_DOCX, success: true, docx });
        }).catch(async (error) => {
            console.log('Error: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
            dispatch({ type: FETCH_DOCX, success: false, error: JSON.stringify(error, null, 4) });
        });
    }
}

export function loadDocument(id, singleClauseId) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DOCUMENT });
        try {
            const doc = await api.documents.getDocument(id, singleClauseId);
            if (!singleClauseId) initPushUpdates(dispatch, doc.id);
            dispatch({ type: FETCH_DOCUMENT, success: true, document: doc, singleClauseId });
            console.log('document load complete');
        } catch (error) {
            localStorage.removeItem('current_user');
            localStorage.removeItem('access_token');
            dispatch({ type: FETCH_DOCUMENT, success: false, error });
        }
    };
}

export function loadOrganization(id) {
    return async (dispatch) => {
        dispatch({ type: LOAD_ORGANIZATION });
        try {
            const organization = await api.organization.getOrganization(id);
            dispatch({ type: LOAD_ORGANIZATION, success: true, organization: organization });
            console.log('organization load complete');
        } catch (error) {
            dispatch({ type: LOAD_ORGANIZATION, success: false, error });
        }
    };
}

export function saveDocumentMondaySync({ documentId, sync_monday }) {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_DOCUMENT, data: { sync_monday }});
    try {
        await api.documents.updateDocument(documentId, {sync_monday});
        dispatch({ type: UPDATE_DOCUMENT, success: true});
    } catch (error ) {
        dispatch({ type: UPDATE_DOCUMENT, success: false, error });
    }
  }
}

export function saveSignatureType({ documentId, signature_type }) {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_DOCUMENT, data: { signature_type }});
        try {
            await api.documents.updateDocument(documentId, {signature_type});
            dispatch({ type: UPDATE_DOCUMENT, success: true});
        } catch (error ) {
            dispatch({ type: UPDATE_DOCUMENT, success: false, error });
        }
    }
}

export function saveDocumentIsChecklist({ documentId, is_checklist }) {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_DOCUMENT, data: { is_checklist }});
        try {
            await api.documents.updateDocument(documentId, {is_checklist});
            dispatch({ type: UPDATE_DOCUMENT, success: true});
        } catch (error ) {
            dispatch({ type: UPDATE_DOCUMENT, success: false, error });
        }
    }
}

export function saveDocumentFinalFormat({ documentId, final_format }) {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_DOCUMENT, data: { final_format }});
        try {
            await api.documents.updateDocument(documentId, {final_format});
            dispatch({ type: UPDATE_DOCUMENT, success: true});
        } catch (error ) {
            dispatch({ type: UPDATE_DOCUMENT, success: false, error });
        }
    }
}


export function askAi( prompt, action= 'alternative', text = '' ) {
    return async (dispatch, getState) => {
        dispatch({ type: ASK_AI, data: { prompt, action }});
        try {
            const result = await api.documents.askAi({ prompt, action, text });
            dispatch({ type: ASK_AI, success: true, result: { prompt, action, result }  });
        } catch (error ) {
            dispatch({ type: ASK_AI, success: false, error:error.errorMessage });
        }
    }
}

export function askAiMain( prompt ) {
    return async (dispatch, getState) => {
        dispatch({ type: ASK_AI_MAIN, data: { prompt }});
    }
}

export function saveDocumentOnFinishTargetOrigin({ documentId, on_finish_target_origin }) {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_DOCUMENT, data: { on_finish_target_origin }});
        try {
            await api.documents.updateDocument(documentId, { on_finish_target_origin });
            dispatch({ type: UPDATE_DOCUMENT, success: true});
        } catch (error ) {
            dispatch({ type: UPDATE_DOCUMENT, success: false, error });
        }
    }
}

export function saveDocumentAutoDeleteQuestionnairesTime({ documentId, auto_delete_questionnaires_time }) {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_DOCUMENT, data: { auto_delete_questionnaires_time }});
        try {
            await api.documents.updateDocument(documentId, { auto_delete_questionnaires_time });
            dispatch({ type: UPDATE_DOCUMENT, success: true});
        } catch (error ) {
            dispatch({ type: UPDATE_DOCUMENT, success: false, error });
        }
    }
}

export function saveDocumentCategoryId({ documentId, document_category_id }) {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_DOCUMENT, data: { document_category_id }});
        try {
            await api.documents.updateDocument(documentId, { document_category_id });
            dispatch({ type: UPDATE_DOCUMENT, success: true});
        } catch (error ) {
            dispatch({ type: UPDATE_DOCUMENT, success: false, error });
        }
    }
}


export function saveDocument(newDocName) {
    return async (dispatch, getState) => {
        const { document: { documentId, sync_monday, on_finish_target_origin, auto_delete_questionnaires_time } } = getState();
        if (!documentId) return;

        await Word.run(async context => {
            await dispatch({ type: SAVE_DOCUMENT });
            const xml = context.document.body.getOoxml();
            await context.sync();
            const u = { raw_data: xml.value };
            if (newDocName && newDocName !== document.name) {
                u['name'] = newDocName;
                u['sync_monday'] = sync_monday;
                u['on_finish_target_origin'] = on_finish_target_origin;
            }

            if (auto_delete_questionnaires_time!==undefined){
                u['auto_delete_questionnaires_time'] = auto_delete_questionnaires_time;
            }

            const doc = await api.documents.updateDocument( documentId, u);
            if (doc.is_changed) {
                console.log('releasing and clearing document');
                await releaseContentControls(context);
                await clearDocument(context);
                console.log('released and cleared document');
                context.document.body.insertOoxml(doc.raw_data, Word.InsertLocation.replace);
                await context.sync();    
            } else {
                console.log('not updating unchanged document');
            }
            await dispatch({ type: SAVE_DOCUMENT, success: true, document: doc });
        }).catch(async error => {
            await dispatch({ type: SAVE_DOCUMENT, success: false, error });
            console.log('Error in save document: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    };
}

export function saveClauseAlternatives() {
    return async (dispatch, getState) => {
        const { document: { documentId, singleClauseId } } = getState();
        if (!singleClauseId) return;
        
        await Word.run(async context => {
            dispatch({ type: UPDATE_CLAUSE, clauseId: singleClauseId });
            const xml = context.document.body.getOoxml();
            await context.sync();
            const clause = await api.documents.updateClauseAlternatives(singleClauseId, documentId, xml.value);
            dispatch({ type: UPDATE_CLAUSE, success: true, clause });
        }).catch(error => {
            dispatch({ type: UPDATE_CLAUSE, success: false, error });
        });
    };
}

export function openClause(documentId, clauseId) {
    return async (dispatch) => {
        dispatch({ type: OPEN_CLAUSE });
        return Word.run(async context => {
            const docx = await api.documents.getClauseDocx(documentId, clauseId);
            const newDoc = context.application.createDocument(docx);
            newDoc.properties.title = '[LegalUp Clause Alternatives]';
            newDoc.properties.author = 'LegalUP';
            newDoc.properties.company = 'LegalUP';
            newDoc.properties.category = 'clause';
            newDoc.properties.keywords = documentId + ',' + clauseId;
            newDoc.properties.comments = 'This document is a LegalUP clause alternatives view';
            context.load(newDoc);

            await context.sync();
            newDoc.open();
            await context.sync();    
            dispatch({ type: OPEN_CLAUSE, success: true, docx });
        }).catch(async (error) => {
            console.log('Error: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
            dispatch({ type: OPEN_CLAUSE, success: false, error });
        });
    }
}

export function deleteAlternative(alternativeId, clauseId, documentId) {
    return async (dispatch) => {
        await Word.run(async (context) => {
            dispatch({ type: DELETE_ALTERNATIVE, alternativeId, clauseId, documentId });
            const controls = context.document.contentControls.getByTitle(alternativeId);
            context.load(controls, 'text');
            await context.sync();
            for(let i = 0; i < controls.items.length; ++i) {
                const control = controls.items[i];
                control.cannotDelete = false;
                control.delete();
            }
            await context.sync();    
            const clause = await api.documents.deleteClauseAlternative(clauseId, documentId, alternativeId);
            dispatch({ type: UPDATE_CLAUSE, success: true, clause });
            dispatch({ type: DELETE_ALTERNATIVE, success: true });
        }).catch(error => {
            dispatch({ type: DELETE_ALTERNATIVE, success: false, error });
            console.error('error deleting alternative with id ' + alternativeId);
        });
    }
}

export function selectClause(id) {
    return async (dispatch, getState) => {
        const state = getState();
        if (state.document.alternativesOpen) {
            return;
        }
        dispatch({ type: SELECT_CLAUSE });
        const selectedClause = state.document.selectedClause;
        if (selectedClause && selectedClause.id === id) {
            console.log('skipping selection because id is the same as current selection');
            return;
        }
        return Word.run(async context => {
            const clause = state.document.clauses.find(c => c.id === id );
            if (clause) {
                if (selectedClause && selectedClause.id) {
                    const selectedContainer = getClauseContainer(context, selectedClause.id);
                    if (selectedContainer) {
                        console.log('hiding clause ' + selectedClause.id);
                        selectedContainer.appearance = 'Hidden';
                    }    
                }
                const container = getClauseContainer(context, id);
                if (container) {
                    console.log('boxing clause ' + clause.id);
                    container.getRange('Content').select('Start');
                    container.appearance = 'BoundingBox';
                } else {
                    console.error(`did not find container with id ${id} nothing to select`);
                }
                context.load(container);
                await context.sync();
                dispatch({ type: SELECT_CLAUSE, success: true, clause });
            } else {
                console.error('did not find clause with id ' + id);
            }
        }).catch((error) => {
            dispatch({ type: SELECT_CLAUSE, success: false, error });
            console.log('Error in select clause: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    };
}

export function updateClause(updatedClause, documentId) {
    return async (dispatch) => {
        dispatch({ type: UPDATE_CLAUSE, clauseId: updatedClause.id });
        try {
            const clause = await api.documents.updateClause(updatedClause.id, documentId, updatedClause);
            dispatch({ type: UPDATE_CLAUSE, success: true, clause });
        } catch (error) {
            dispatch({ type: UPDATE_CLAUSE, success: false, error });
        }
    }
}

export function updateClauseName(clauseId, documentId, name) {
    return async (dispatch, getState) => {
        const clauses = getState().document.clauses;
        dispatch({ type: UPDATE_CLAUSE, clauseId });
        try {
            for (let i = 0; i < clauses.length; ++i) {
                if (clauses[i].id === clauseId) {
                    if (clauses[i].name !== name) {
                        const clause = await api.documents.updateClause(clauseId, documentId, { name });
                        dispatch({ type: UPDATE_CLAUSE, success: true, clause });
                    }
                    return;
                }
            }
        } catch (error) {
            dispatch({ type: UPDATE_CLAUSE, success: false, error });
        }        
    }
}

export function setupVariable(containerId) {
    // note that the container id is an internal word maintained id on the content conrol.
    // we just use it as a link to identify the specific variable as we don't store any id
    // in our db.
    return { type: SETUP_VARIABLE, containerId };
}

export function setupSignature(containerId) {
    // note that the container id is an internal word maintained id on the content conrol.
    // we just use it as a link to identify the specific variable as we don't store any id
    // in our db.
    return { type: SETUP_SIGNATURE, containerId };
}

export function setupPhrase(phraseId, clauseId) {
    return async (dispatch, getState) => {
        const { document: { documentId }} = getState();
        try {
            const phrase = await api.documents.getPhrase(documentId, clauseId, phraseId);
            dispatch({ type: SETUP_PHRASE, phrase, success: true });    
        } catch (error) {
            dispatch({ type: SETUP_PHRASE, error, success: false });
        }
    } 
}

export function saveVariable(containerId, question, parentQuestion) {
    return async (dispatch) => {
        dispatch(setupVariable(null));
        return Word.run(async (context) => {
            const control = context.document.contentControls.getById(containerId);
            control.cannotEdit = false;
            control.title = question.id;
            control.insertText(` ${parentQuestion ? parentQuestion.title + ' - ': ''}${question.title} `, 'Replace');
            await context.sync();
            control.cannotEdit = true;
            await context.sync();
            dispatch({ type: SAVE_VARIABLE, success: true });
        }).catch(error => {
            dispatch({ type: SAVE_VARIABLE, success: false, error });
        });
    }
}

export function saveSignature(containerId, question, parentQuestion) {
    return async (dispatch) => {
        dispatch(setupSignature(null));
        return Word.run(async (context) => {
            const control = context.document.contentControls.getById(containerId);
            control.cannotEdit = false;
            control.title = question.id;
            control.insertText(` ${parentQuestion ? parentQuestion.title + ' - ': ''}${question.title} `, 'Replace');
            await context.sync();
            control.cannotEdit = true;
            await context.sync();
            dispatch({ type: SAVE_SIGNATURE, success: true });
        }).catch(error => {
            dispatch({ type: SAVE_SIGNATURE, success: false, error });
        });
    }

}

export function updatePhrase(clauseId, updatePhrase) {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_PHRASE, phrase: updatePhrase, clauseId });
        return Word.run(async (context) => {
            const { document: { documentId }} = getState();
            await updatePhraseContainer(context, updatePhrase);
            const phrase = await api.documents.updatePhrase(documentId, clauseId, updatePhrase.id, updatePhrase);
            phrase.clause_id = clauseId;
            dispatch({ type: UPDATE_PHRASE, success: true, phrase });
        }).catch(error => {
            dispatch({ type: UPDATE_PHRASE, success: false, error });
        });
    }
}

export function deletePhrase(clauseId, phraseId) {
    return async (dispatch, getState) => {
        dispatch({ type: DELETE_PHRASE, phraseId, clauseId });
        return Word.run(async (context) => {
            // const { document: { documentId }} = getState();
            await deletePhraseContainer(context, phraseId);
            // await api.documents.deletePhrase(documentId, clauseId, phraseId);
            dispatch({ type: DELETE_PHRASE, success: true, phraseId, clauseId });
        }).catch(error => {
            dispatch({ type: DELETE_PHRASE, success: false, error });
        });
    }
}

//questions
export const FETCH_QUESTIONS = "FETCH_QUESTIONS";
export const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";
export const SET_ERRORS = "SET_ERRORS";
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const ENSURE_QUESTION = "ENSURE_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const SWITCH_QUESTIONS = "SWITCH_QUESTIONS";

export const questionKinds = ['Single question', 'Multi questions form'];
export const questionKindsValues = ['single_question', 'multi_questions_form'];
export const questionTypesValues = ['text_box', 'number', 'email', 'address', 'date', 'attachment', 'drop_down', 'checkboxes', 'radio_buttons']; 
export const repeatKindsValues = ['do_no_repeat', `let_user_add`, 'repeat_for_every_answer', 'repeat_for_every_value'];

export function initQuestion(questionId) {
    return async (dispatch, getState) => {
        const questions = getState().questions.questions;
        let q = (questionId) ? questions.find(q => q.id === questionId): {
            id: ObjectId(),
            type: questionTypesValues[0],
            repeat_type: repeatKindsValues[0]
        };
        await dispatch({ type: SET_CURRENT_QUESTION, question: q });
    };
}

export function loadQuestions(documentId) {
    return async (dispatch, getState) => {        
        if (!documentId) return;
        if (getState().questions.questions && getState().questions.questions.length > 0 ) return; //dont copy from doc if there are questions already
        dispatch({ type: FETCH_QUESTIONS });
        try {
            const qs = getState().document.questions;
            const output = [];
            qs.forEach(q => {
                output.push(q);
                q.children.forEach(c => output.push(c));
            });
            dispatch({ type: FETCH_QUESTIONS, success: true, questions: output });
        } catch (error) {
            console.error(error);
            dispatch({ type: FETCH_QUESTIONS, success: false, error });
        }
    };
}

export function setCurrentQuestion(questionUpdate) {
    return async(dispatch, getState) => {
        await dispatch({type: SET_CURRENT_QUESTION, question: questionUpdate});
    };
}

export function setErrors(errorsUpdate) {
    return async(dispatch, getState) => {
        await dispatch({type: SET_ERRORS, errors: errorsUpdate});
    };
}

export function updateQuestion(docId, passedQuestionId, currentQuestion) {
    return async(dispatch, getState) => {
        dispatch({type: UPDATE_QUESTION});
        try {
            const ret = await api.documents.updateQuestion(docId, passedQuestionId, currentQuestion);
            await dispatch({type: UPDATE_QUESTION, success: true, question: ret});
            } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_QUESTION, success: false, error });
        }
    };
}

export function ensureQuestion(docId, question) {
    return async(dispatch, getState) => {
        dispatch({type: ENSURE_QUESTION});
        try {
            const returnedQuestion = await api.documents.ensureQuestion(docId, question);
            // await dispatch(loadDocument(docId));            
            await dispatch({type: ENSURE_QUESTION, success: true, question: returnedQuestion});
        } catch (error) {
            console.error(error);
            dispatch({ type: ENSURE_QUESTION, success: false, error });
        }
    };
}

export function deleteQuestion(docId, qID) {
    return async(dispatch, getState) => {
        dispatch({type: DELETE_QUESTION});
        try {
            await api.documents.deleteQuestion(docId, qID);
            dispatch({type: DELETE_QUESTION, success: true, question_id: qID});
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_QUESTION, success: false, error });
        }
    };
}

export function switchQuestions(docId, id1, id2) {
    return async(dispatch, getState) => {
        const result = await api.documents.switchQuestions(docId, id1, id2);
        dispatch({type: SWITCH_QUESTIONS, success: result.success, question_id1: id1, question_id2: id2});
    };
}

export function setSelectedTab(selectedTab) {
    return async(dispatch, getState) => {
        await dispatch({type: SET_SELECTED_TAB, tab: selectedTab});
    };
}

export function deleteDocument(docId) {
    return async(dispatch, state) => {
        dispatch({ type: DELETE_DOCUMENT });
        try {
            const doc = await api.documents.deleteDocument(docId);
            dispatch({ type: DELETE_DOCUMENT, success: true, deleted: docId });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_DOCUMENT, success: false, error });
        }
    };
}

export function copyDocument(docId) {
    return async(dispatch, state) => {
        dispatch({ type: COPY_DOCUMENT });
        try {
            const doc = await api.documents.copyDocument(docId);
            dispatch({ type: COPY_DOCUMENT, success: true, copiedDocument: doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: COPY_DOCUMENT, success: false, error });
        }
    };
}

export function saveDocumentType(data) {
    return async(dispatch, state) => {
        dispatch({ type: SAVE_DOCUMENT_TYPE });
        try {
            const docType = await api.taxonomy.createDocumentType(data);
            dispatch({ type: SAVE_DOCUMENT_TYPE, success: true, documentType: docType });
        } catch (error) {
            console.error(error);
            dispatch({ type: SAVE_DOCUMENT_TYPE, success: false, error });
        }
    };
}


export function getDocumentType(leng) {
    return async(dispatch, state) => {
        dispatch({ type: DOCUMENT_TYPE });
        try {
            const docType = await api.taxonomy.getDocumentTypeByLanguage(leng);

            dispatch({ type: DOCUMENT_TYPE, success: true, documentType: docType });
        } catch (error) {
            console.error(error);
            dispatch({ type: DOCUMENT_TYPE, success: false, error });
        }
    };
}

export function getClauseCategories(leng) {
    return async(dispatch, state) => {
        dispatch({ type: CLAUSE_CATEGORIES });
        try {
            const clauseCategories = await api.taxonomy.clauseCategories(leng);
            dispatch({ type: CLAUSE_CATEGORIES, success: true, clauseCategories });
        } catch (error) {
            console.error(error);
            dispatch({ type: CLAUSE_CATEGORIES, success: false, error });
        }
    };
}

export function createClause(data) {
    return async(dispatch, getState) => {
        dispatch({type: SAVE_CLAUSE});
        try {
            await api.taxonomy.createClause(data);
            dispatch({ type: RESET_TAGS });
        } catch (error) {
            console.error(error);
        }
    };
}

export function clauseSearch(search) {
    return async(dispatch, getState) => {
        dispatch({type: CLAUSES_PORTFOLIO_SEARCH, success: true, search});

    };
}

export function getClauses(text) {
    return async(dispatch, state) => {
        dispatch({ type: GET_CLAUSES });
        try {
            const clauses = await api.taxonomy.getClauses(text);
            dispatch({ type: GET_CLAUSES, success: true, clauses });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_CLAUSES, success: false, error });
        }
    };
}

export function deleteClauses(id) {
    return async(dispatch, state) => {
        dispatch({ type: DELETE_CLAUSES });
        try {
            await api.taxonomy.deleteClauses(id);
            const clauses = await api.taxonomy.getClauses('');
            dispatch({ type: GET_CLAUSES, success: true, clauses });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_CLAUSES, success: false, error });
        }
    };
}

export function editClauses(id, data) {
    return async(dispatch, state) => {
        dispatch({ type: EDIT_CLAUSES });
        try {
            await api.taxonomy.editClauses(id, data);
            dispatch({ type: RESET_TAGS });
        } catch (error) {
            console.error(error);
            dispatch({ type: EDIT_CLAUSES, success: false, error });
        }
    };
}

export function resetReducer(id, data) {
    return async(dispatch, state) => {
        dispatch({ type: RESET_REDUCER });
    };
}

export function resetTags(id, data) {
    return async(dispatch, state) => {
        dispatch({ type: RESET_TAGS });
    };
}

export function resetСlauseSearch() {
    return async(dispatch, state) => {
        dispatch({ type: RESET_CLAUSE_SEARCH });
    };
}

export function getCategories() {
    return async(dispatch) => {
        dispatch({ type: GET_CATEGORIES });
        try {
            const doc = await api.organization.getCategory();
            dispatch({ type: GET_CATEGORIES, success: true, categories: doc });
        } catch (error) {
            console.error({error});
            dispatch({ type: GET_CATEGORIES, success: false, error });
            throw error;
        }
    };
}

export function createCategory(category) {
    return async (dispatch) => {
        dispatch({ type: CREATE_CATEGORY });
        try {
            const c = await api.organization.createCategory(category);
            dispatch({ type: CREATE_CATEGORY, success: true, category: c });
        } catch (error) {
            console.error({ error });
            dispatch({ type: CREATE_CATEGORY, success: false, error });
        }
    }
}

export function deleteCategory(id) {
    return async (dispatch) => {
        dispatch({ type: DELETE_CATEGORY });
        try {
            const c = await api.organization.deleteCategory(id);
            dispatch({ type: DELETE_CATEGORY, success: true, category: c });
        } catch (error) {
            console.error({ error });
            dispatch({ type: DELETE_CATEGORY, success: false, error });
        }
    }
}

export function saveCategoryInMemory(id, name, isNew = null) {
    return async (dispatch, getState) => {
        dispatch({ type: SAVE_CATEGORY_IN_MEMORY, id, name, isNew });
    }
}